.diyherocontainer {
  background-color: #101011;
  padding: 5rem 0rem;
}
.diycontent {
  width: 100%;
  margin: auto;
  gap: 6rem;
  flex-direction: row-reverse;
}
.diyleft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3rem;
  width: 40%;
}
.diytitle {
  font-family: Syne;
  font-size: 42px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
.diydesc {
  font-family: Syne;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
.diyleft .downloadbutton {
  width: 30%;
}
.diyright {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: auto;
  object-fit: contain;
  border-radius: inherit;
  scale: 0.8;
  border-radius: 7rem;
}
.diyright > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 1rem;
}
.diyfeaturecontainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #1d1d1e;
  width: fit-content;
  padding: 3rem;
  border-radius: 1rem;
}
.diyfeaturecontainer p {
  font-family: Syne;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.diyfeaturecontainer > div:nth-child(1),
.diyfeaturecontainer > div:nth-child(2) {
  border-bottom: 1px solid #363738;
  padding-bottom: 2rem;
}

.threeclick > img {
  max-width: 18%;
  height: auto;
  object-fit: contain;
}
.appscommingsson {
  font-size: 2.5rem;
  letter-spacing: 0.1rem;
}
@media (max-width: 768px) {
  .diycontent {
    flex-direction: column;
    width: 90%;
    padding: 3rem 0rem;
  }
  .diyright {
    width: 100%;
  }
  .diyleft {
    width: 100%;
  }
}
