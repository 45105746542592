.photoherocontainer {
  background-color: #1d1d1e;
}
.photocontent {
  width: 85%;
  margin: auto;
  gap: 10rem;
  padding: 5rem 0rem;
}
.photoleft {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}
.photoleft .w-auto{
  width: 40%;
 
}
.phototitle {
  font-family: Syne;
  font-size: 35px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
}

.photodesc {
  font-family: Syne;
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
}
.photoright {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  scale: 0.8;
}
.photoright > img {
  width: 100%;
  /* height: 80%; */
  object-fit: cover;
  border-radius: 1rem;
  filter: brightness(0.8);
  filter: contrast(0.8);
}
.photoright > img:hover {
  filter: brightness(1);
  filter: blur(0px);
  transition: all 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .photoherocontainer {
    padding: 3rem 0rem;
  }
  .photocontent {
    flex-direction: column;
  }
  .photoleft {
    width: 100%;
  }
  .photocontent {
    gap: 2rem;
  }
  .photoright {
    width: 65%;
    margin: auto;
    gap: 1rem;
    filter: brightness(1);
    filter: blur(0px);
  }
  .photoright > img {
    width: 50%;
  }
}
