.imagegallerycontainer {
  background-color: #1d1d1e;
  padding: 4rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.gallerytitle {
  font-size: 42px;
  font-weight: 700;
  line-height: 55px;
  letter-spacing: 0px;
  text-align: center;
  color: var(--white);
}
.imagegallerycontainer > img {
  width: 90%;
  margin: auto;
}
@media (max-width: 767px) {
  .imagegallerycontainer {
    background-color: #1d1d1e;
    padding: 2rem 0rem;
    gap: 2rem;
  }
}
