.heroseccontainer {
  background-color: var(--primarycolor);
  padding: 3.5rem 0rem;
  justify-content: center;
}
.eone {
  position: relative;
  left: 87%;
  bottom: 20vh;
}
.etwo {
  position: relative;
  bottom: 32vh;
  width: 18%;
  left: -5%;
}
.ethree {
  position: relative;
  bottom: 40vh;
  width: 30%;
  left: 75%;
}
.heroleft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  position: relative;
  z-index: 2;
  margin-top: 0;
  margin-bottom: 0rem;
  margin-left: 0;
  width: 42%;
  margin-right: 0;
}

.herotitle {
  font-family: var(--font-family-syne);
  color: var(--headingcolor);
  font-size: 5.1rem;
  font-weight: 700;
  line-height: 6.5rem;
  letter-spacing: 0em;
  background: -webkit-linear-gradient(
    #ffffff 0%,
    rgba(255, 255, 255, 0) 230.63%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.herodescription {
  font-family: var(--font-family-nunito);
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 3.1rem;
  letter-spacing: 0em;
  color: #7c7c7c;
  margin: 1.5rem 0rem;
}
.downloadbutton {
  width: 55%;
  display: flex;
  justify-content: space-between;
}
.heroseccontainer a > .playstore,
.heroseccontainer a > .appstore {
  width: 90%;
}
.herobottom {
  padding: 0;
}
.mainherimgiphone {
  justify-content: center;
  align-items: center;
  transform: scale(0.8);
}
.iphoneimgsize1 {
  transform: translate(7.5rem);
  z-index: -2;
}
.iphoneimgsize2 {
  transform: translate(4.5rem);
  z-index: -1;
}

.iphoneimgsize4 {
  transform: translate(-4.5rem);
  z-index: -1;
}
.iphoneimgsize5 {
  transform: translate(-7.5rem);
  z-index: -2;
}
.heroright {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  width: 40%;
}
.heroimgwidth {
  width: 78%;
}
@media (max-width: 768px) {
  .heroseccontainer {
    flex-direction: column;
  }
  .herotitle {
    width: 90%;
    font-size: 2.9rem;
    font-weight: 700;
    line-height: 5rem;
    text-align: center;
  }
  .herodescription {
    font-size: 2rem;
    line-height: 3rem;
    width: 90%;
    text-align: center;
  }
  .downloadbutton {
    width: 75%;
  }
  .herobottom {
    width: 90%;
    margin: auto;
    padding: 0;
    margin-top: 2rem;
  }
  .mainherimgiphone {
    margin: auto;
    justify-content: center;
    align-items: center;
    transform: scale(0.2);
    height: 30vh;
  }
  .iphoneimgsize3 {
    transform: scale(1.3);
  }
  .eone {
    position: relative;
    left: 69%;
    bottom: 25vh;
  }
  .etwo {
    position: relative;
    bottom: 29vh;
    width: 40%;
  }
  .ethree {
    position: relative;
    bottom: 16vh;
    left: 63%;
    width: 57%;
  }
  .heroleft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    position: relative;
    z-index: 0;
    width: 100%;
  }
  .heroright {
    margin-top: 3rem;
    width: 100%;
  }
}
