.NetworkofPhotographer {
  background-color: #111010;
  color: white;
  padding: 5rem 0rem;
}
.nwtoptilte {
  font-family: Syne;
  font-size: 4.2rem;
  font-weight: 700;
  line-height: 70px;
  letter-spacing: 0px;
  text-align: center;
}

.nwttopdesc {
  font-family: Syne;
  font-size: 1.9rem;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 5rem;
  margin-top: 1rem;
}

.nwmiddle {
  padding: 4rem 1rem 0rem 1rem;
  width: 50%;
  border-radius: 5rem;
}
.findtitle {
  font-family: Syne;
  font-size: 2rem;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  width: fit-content;
  margin: auto;
}
.finddesc {
  font-family: Syne;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.7rem;
  width: 80%;
  letter-spacing: 0em;
  text-align: center;
  margin: auto;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}
.phoneimageset {
  max-width: 75%;
  margin: auto;
}
.nwleft {
  align-items: flex-end;
}
.nwright {
  align-items: flex-start;
}

@media (max-width: 768px) {
  .nwbottom {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .nwmiddle {
    padding: 3rem 1rem 0rem 1rem;
    width: 90%;
    border-radius: 5rem;
    order: -1;
  }
  .nwleft {
    align-items: center;
  }
  .nwright {
    align-items: center;
  }
}
