.hitphotographercontainer {
  background-color: #1d1d1e;
  padding: 7rem 10rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.hitpuppercontainer {
  font-family: var(--font-family-syne);
  font-size: 5.2rem;
  font-weight: 700;
  line-height: 8.3rem;
  letter-spacing: 0em;
  text-align: center;
  text-transform: capitalize;
  background: linear-gradient(90deg, #ffffff, #a7a7a7);
  background-clip: text;
  color: transparent;
  width: fit-content;
  margin: auto;
}

.hitpcardtitle {
  font-family: Syne;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
}
.hitpcarddesc {
  font-family: Syne;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.hitpbelowcontainer {
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  position: relative;
}

.hitpcard1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  gap: 1.5rem;
}
.hitpbelowcontainer > img:first-child {
  position: absolute;
  width: 18%;
  right: 57%;
  bottom: 80%;
}

.hitpbelowcontainer > img:nth-child(2) {
  position: absolute;
  width: 18%;
  right: 25%;
  bottom: 55%;
}

@media (max-width: 768px) {
  .hitphotographercontainer {
    background-color: #1d1d1e;
    padding: 2rem 2rem;
    gap: 1rem;
  }
  .hitpuppercontainer {
    font-family: var(--font-family-syne);
    font-size: 4.2rem;
  }
  .hitpbelowcontainer {
    flex-direction: column;
  }
  .hitpcard1 {
    display: flex;
    width: 100%;
  }
  .hitpbelowcontainer > img:first-child {
    display: none;
  }

  .hitpbelowcontainer > img:nth-child(2) {
    display: none;
  }
}
