.explorecontainer {
  background-color: #101110;
  padding: 0rem 0rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
  position: relative;
}
.explorebgblue {
  background-color: #015be4;
  height: 350px;
  padding: 0rem 12rem;
  align-items: center;
  color: white;
}
.ebgleft {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 3rem;
}
.ebgleft .downloadbutton {
  display: flex;
  align-items: flex-start;
  gap: 3rem;
  width: 40%;
}
.ebgtitle {
  font-family: Syne;
  font-size: 35px;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: 0px;
  text-align: left;
}
.ebgdesc {
  font-family: Syne;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.ebgbutton {
  font-family: Syne;
  font-size: 14px;
  font-weight: 700;
  line-height: 10px;
  letter-spacing: 0px;
  text-align: center;
  background: linear-gradient(90deg, #015be4 0%, #015be4 0%, #4ab11f 100%);
  padding: 2rem 2rem;
  border: 2px solid white;
  border-radius: 4rem;
}
.ebgright > img {
  position: relative;
  bottom: 3.2rem;
}

@media (max-width: 768px) {
  .explorebgblue {
    padding: 0;
    flex-direction: column;
    height: 45vh;
  }
  .ebgright > img {
    display: none;
  }
  .ebgleft {
    height: 100%;
    justify-content: center;
    padding: 0rem 2rem;
  }
}
